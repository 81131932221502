<script>
import Accordeon from "@/components/widgets/Accordeon.vue";

export default {
  components: { Accordeon, ItemUserConnected: () => import("./ItemUserConnected.vue") },
  props: {
    users: { type: Array, default: () => [] },
    rowInfo: { type: Object },
  },
  data() {
    return {
      showCategories: false,
    };
  },
  computed: {
    usersfiltered() {
      const users = {
        online: [],
        offline: [],
        spark: [],
      };

      this.users.forEach((user) => {
        const { isOnline, getBy } = user;
        const groupUser = isOnline ? "online" : "offline";

        if (getBy === "spark") {
          users.spark.push(user);
        }

        users[groupUser].push(user);
      });
      return users;
    },
    totalUsers() {
      const { online, offline } = this.usersfiltered;
      return online.length + offline.length;
    },
    categories() {
      const categoriesMap = {
        global: [],
        sedes: [],
      };

      this.users.forEach((user) => {
        const { city } = user;
        const groupUser = city === "Global" ? "global" : "sedes";

        categoriesMap[groupUser].push(user);
      });
      return categoriesMap;
    },
  },
};
</script>

<template>
  <section :class="`modelsConnected`">
    <div class="modelsConnected__header" @click="showCategories = !showCategories">
      <h5 class="modelsConnected__title">modelos</h5>
      <div class="modelsConnected__contentBadge">
        <div class="tool-tip">
          <div class="modelsConnected__badge modelsConnected__badge--spark">
            {{ usersfiltered.spark.length }}
          </div>
          <span class="tooltiptext tool-auto">Spark</span>
        </div>
        <div class="tool-tip">
          <div class="modelsConnected__badge modelsConnected__badge--online">
            {{ usersfiltered.online.length - usersfiltered.spark.length }}
          </div>
          <span class="tooltiptext tool-auto">Conectados</span>
        </div>
        <div class="tool-tip">
          <div class="modelsConnected__badge modelsConnected__badge--offline">
            {{ usersfiltered.offline.length }}
          </div>
          <span class="tooltiptext tool-auto">Desconectados</span>
        </div>
        <div class="tool-tip">
          <div class="modelsConnected__badge modelsConnected__badge--total">
            {{ totalUsers }}
          </div>
          <span class="tooltiptext tool-auto">Total</span>
        </div>
      </div>
    </div>
    <template v-if="showCategories">
      <ItemUserConnected
        class="modelsConnected__itemUsers"
        v-for="(categorie, key, idx) in categories"
        :key="idx"
        :users="categorie"
        :rowInfo="{ title: key }"
        component="models"
      />
    </template>
  </section>
</template>

<style lang="scss">
.modelsConnected {
  @include Flex(column);
  gap: 10px;
  padding: $mpadding;
  border-radius: 4px;
  background-color: $white;
  box-shadow: $dshadow;
  &__header {
    @include Row(flex-start, center);
    width: 100%;
    cursor: pointer;
  }
  &__title {
    font-size: 16px;
    flex-grow: 10;
    font-weight: 400;
    color: $black;
  }
  &__contentBadge {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
    max-width: 217px;
    gap: 7px;
  }
  &__badge {
    @include Row();
    height: 22px;
    width: 100%;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 600;
    color: $white;
    border-radius: 15px;
    &--spark {
      color: #ff8000bc;
      background-color: #fd9b391c;
    }
    &--offline {
      color: $chicago;
      background-color: #5b59571c;
    }
    &--online {
      color: $la_palma;
      background-color: #1f9e0c1f;
    }
    &--total {
      color: $primary-color;
      background-color: #bd090914;
    }
  }
  &__itemUsers {
    width: 100%;
  }
  @include tabletWidth() {
    &__title {
      font-size: 19px;
    }
    &__contentBadge {
      grid-template-columns: repeat(auto-fit, minmax(46px, 1fr));
      min-width: 217px;
    }
    &__badge {
      height: 25px;
      padding: 0 15px;
      font-size: 16px;
    }
  }
}
</style>
